.notebook-container {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .notebook-scrollable {
    display: flex;
    overflow-x: auto;
  }
  
  .notebook-column {
    flex: 0 0 auto;
    padding: 10px;
    min-width: 150px; /* Set minimum width for columns */
  }
  
  .notebook-column-header {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .notebook-row {
    margin-bottom: 5px;
  }
  
  .even-row {
    background-color: #f2f2f2; /* Background color for even rows */
  }
  