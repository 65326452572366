.button-list-container {
    overflow-x: auto;
    white-space: nowrap; /* Prevents buttons from wrapping to new lines */
  }
  
  .button-list {
    display: flex;
  }
  
  button {
    padding: 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  button:last-child {
    margin-right: 0; /* Remove right margin from the last button */
  }
  
  button:hover {
    background-color: #0056b3;
  }
  