.MissingValues-drop {
    width: 50%;
    margin: -10px 0px 20px;
    padding: 10px;
    border: 2px solid #deb887;
    color: #926d35;
    background-color: #f0c0812a;
}

.MissingValues-drop-list {
    width: fit-content;
    margin: 20px 0px;
    padding: 10px;
    border: 2px solid #131035;
    color: #112143;
    background-color: #4249962a;
}

.MissingValuesTable {
    font-family: Arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .MissingValuesTable th,
  .MissingValuesTable td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .MissingValuesTable th {
    background-color: #f2f2f2;
  }
  
  .MissingValuesTable td.MissingValueCell {
    background-color: #ffffcc; /* Yellow background for imputed cells */
  }
  
  @media screen and (max-width: 600px) {
    .MissingValuesTable {
      overflow-x: auto;
      display: block;
    }
  
    .MissingValuesTable th, .MissingValuesTable td {
      padding: 6px;
      font-size: 12px;
    }
  }
  